import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import 'moment/locale/nl';
import Benchmark from './benchmark';
import Loadtest from './loadtest';
import ScanHeader from './scan-blocks/scan-header';
import LighthouseBlock from './scan-blocks/lighthouse';
import WebVitalsBlock from './scan-blocks/web-vitals';
import Spacer from './components/spacer';

moment.locale('nl');

const ScanResult = ({
  className,
  title,
  heroBlockImage,
  scans,
  // internalLink,
}) => {
  // const { data: stats } = useStats(scan?.testUrl ? formatUrl(scan?.testUrl) : '');

  // Gatsby's internals use window for the navigate function. This workaround
  // fixes a SSR issue.
  // useEffect(() => {
  //   if (error || (scan && !scan.testUrl)) {
  //     navigate(internalLink?.frontmatter?.slug ?? '/');
  //   }
  // }, [error, internalLink, scan]);
  const [scan] = scans;


  const marginLeft = Math.max(...scans.map((t) => t.testUrl.replace('https://', '').length)) * 5 + 5;

  // TODO: Calc top percentage!

  return (
    <PerformanceScanWrapper>
      <section className={className}>

        <ScanHeader scan={scan} title={title} heroBlockImage={heroBlockImage} />

        <LighthouseBlock scan={scan} />

        <Spacer height={2} />

        <WebVitalsBlock scan={scans} marginLeft={marginLeft} />

        <CompareBar backgroundColor="#F8EDF1">
          <FlexRow center>
            <CompareBarImg src="/img/graph.svg" />
            <CompareBarTextWrapper>
              <CompareBarText>
                Benchmark
                {/* {i18n('performanceScanCompareWithCompetitors', 'Benchmark')} */}
              </CompareBarText>
            </CompareBarTextWrapper>
          </FlexRow>
        </CompareBar>
        <ShowOn desktop>
          <Spacer height={2} />
        </ShowOn>

        <Benchmark scans={scans} marginLeft={marginLeft} />

        <CompareBar backgroundColor="#F9F1E8">
          <FlexRow center>
            <CompareBarImg src="/img/server-statistics-dots.svg" />
            <CompareBarTextWrapper>
              <CompareBarText>
                Loadtest
                {/* {i18n('performanceScanCompareWithCompetitors', 'Benchmark')} */}
              </CompareBarText>
            </CompareBarTextWrapper>
          </FlexRow>
        </CompareBar>
        <Loadtest scans={scans} />

      </section>
    </PerformanceScanWrapper>
  );
};

ScanResult.defaultProps = {
  scanIndex: 0,
};

const ShowOn = styled.div`
  ${(props) => `
        @media all and (${props.desktop ? 'max' : 'min'}-width: ${props.theme.breakingpoints.md}) {
          display: none;
        }
      `}
`;

const CompareBar = styled.div`
  background-color: ${(props) => props.backgroundColor || '#F8EDF1'};
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CompareBarImg = styled.img`
  padding-right: 2rem;
  height: 75px;
`;

const CompareBarTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CompareBarText = styled.h3`
  font-size: 2rem;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  ${(props) => {
    if (props.center) {
      if (props.center === true) {
        return `
        justify-content: center;
        align-items: center;  
      `;
      }
      if (props.center === 'h') {
        return 'justify-content: center;';
      }
      if (props.center === 'v') {
        return 'align-items: center;';
      }
    }

    return null;
  }}

  ${(props) => (props.wrap ? `flex-wrap: ${props.wrap};` : null)}
`;

const PerformanceScanWrapper = styled.section`
  margin-top: 1rem;

  @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    margin-top: 3rem;
  }
`;


export default ScanResult;
