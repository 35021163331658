import React from 'react';
import Container from '../../components/container';
import * as theme from '../../layout/theme';
import TransactionRateGraph from './graphs/TransactionRateGraph';
import AvailabilityGraph from './graphs/AvailabilityGraph';
import ConcurrencyGraph from './graphs/ConcurrencyGraph';
import TransactionsGraph from './graphs/TransactionsGraph';
import { ChartContainer, ChartFlexRow } from './components/html';

const colors = [
  theme.primaryColor,
  theme.secondaryColor,
  theme.fontColor,
  theme.fontColorLight,
  '',
];

function Loadtest({ scans }) {
  return (
    <>
      <Container>
        <ChartFlexRow wrap="wrap">
          <ChartContainer>
            <TransactionRateGraph scans={scans} colors={colors} />
          </ChartContainer>
          <ChartContainer>
            <AvailabilityGraph scans={scans} colors={colors} />
          </ChartContainer>
          <ChartContainer>
            <ConcurrencyGraph scans={scans} colors={colors} />
          </ChartContainer>
          <ChartContainer>
            <TransactionsGraph scans={scans} colors={colors} />
          </ChartContainer>
        </ChartFlexRow>
      </Container>
    </>
  );
}

export default Loadtest;
