import React from 'react';
import {
  Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis,
} from 'recharts';

function HorizontalGraph({
  scores, marginLeft, colors, valueFormatter, xAxisDomain, yAxisDomain,
}) {
  return (
    <ResponsiveContainer>
      <BarChart
        layout="vertical"
        width={500}
        height={300}
        data={scores}
        margin={{
          left: marginLeft,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" tickFormatter={valueFormatter} domain={xAxisDomain} />
        <YAxis dataKey="name" type="category" domain={yAxisDomain} />
        <Tooltip formatter={valueFormatter} />
        <Legend />
        <Bar dataKey="desktop" fill={colors[0]} />
        <Bar dataKey="mobile" fill={colors[1]} />
      </BarChart>
    </ResponsiveContainer>
  );
}

HorizontalGraph.defaultProps = {
  valueFormatter: null,
  xAxisDomain: [0, 'auto'],
  yAxisDomain: [0, 'auto'],
};

export default HorizontalGraph;
