import React from 'react';
import Container from '../../components/container';
import * as theme from '../../layout/theme';
import HipexScoreGraph from './graphs/HipexScoreGraph';
import LightHouseScoreGraph from './graphs/LightHouseScoreGraph';
import TTIScoreGraph from './graphs/TTIScoreGraph';
import TTFBScoreGraph from './graphs/TTFBScoreGraph';
import SpeedIndexScoreGraph from './graphs/SpeedIndexScoreGraph';
import { ChartContainer, ChartFlexRow } from './components/html';

const colors = [
  theme.primaryColor,
  theme.secondaryColor,
  theme.fontColor,
  theme.fontColorLight,
  '',
];

function Benchmark({ scans, marginLeft }) {
  return (
    <>
      <Container>
        <ChartFlexRow wrap="wrap">
          <ChartContainer size={1}>
            <HipexScoreGraph scans={scans} marginLeft={marginLeft} colors={colors} />
          </ChartContainer>
          <ChartContainer>
            <SpeedIndexScoreGraph scans={scans} marginLeft={marginLeft} colors={colors} />
          </ChartContainer>
          <ChartContainer>
            <LightHouseScoreGraph scans={scans} marginLeft={marginLeft} colors={colors} platform="desktop" />
          </ChartContainer>
          <ChartContainer>
            <TTIScoreGraph scans={scans} marginLeft={marginLeft} colors={colors} />
          </ChartContainer>
          <ChartContainer>
            <TTFBScoreGraph scans={scans} marginLeft={marginLeft} colors={colors} />
          </ChartContainer>
        </ChartFlexRow>
      </Container>
    </>
  );
}

export default Benchmark;
