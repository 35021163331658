import React from 'react';
import Container from '../../components/container';
import * as theme from '../../layout/theme';
import LighthouseGraphComponent from './components/chart-components';
import { ChartContainer, ChartFlexRow } from './components/html';

const colors = [
  theme.primaryColor,
  theme.secondaryColor,
  theme.fontColor,
  theme.fontColorLight,
  '',
];

function WebVitals({ scans, marginLeft }) {
  return (
    <>
      <Container>
        <ChartFlexRow wrap="wrap">
          <ChartContainer size={3}>
            <LighthouseGraphComponent
              scans={scans}
              marginLeft={marginLeft}
              colors={colors}
              scoreId="largest-contentful-paint"
              calc={(val) => val / 1000}
              valueProp="numericValue"
              title="Largest Contentful Paint"
              tooltipFormatter={(val) => `${parseFloat(val).toFixed(2)} s`}
            />
          </ChartContainer>
          <ChartContainer size={3}>
            <LighthouseGraphComponent
              scans={scans}
              marginLeft={marginLeft}
              colors={colors}
              scoreId="total-blocking-time"
              valueProp="numericValue"
              title="Total Blocking Time"
              tooltipFormatter={(val) => `${val.toFixed(0)}ms`}
            />
          </ChartContainer>
          <ChartContainer size={3}>
            <LighthouseGraphComponent
              xAxisDomain={[0, 1]}
              scans={scans}
              marginLeft={marginLeft}
              colors={colors}
              scoreId="cumulative-layout-shift"
              valueProp="numericValue"
              tooltipFormatter={(val) => `${val.toFixed(3)}`}
              title="Cumulative Layout Shift"
            />
          </ChartContainer>
        </ChartFlexRow>
      </Container>
    </>
  );
}

export default WebVitals;
