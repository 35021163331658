import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const getColor = (percentage) => {
  if (percentage < 50) {
    return '#EA4D4E';
  }
  if (percentage < 70) {
    return '#F5A462';
  }
  return '#9CC768';
};

const CircularChart = ({ percentage, children, index = 0 }) => {
  const [anim, setAnim] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setAnim(true);
    }, 100 * (index + 1));
  }, [index]);
  return (
    <div>
      <Circle viewBox="-2 -2 40 40" percentage={percentage}>
        <path
          className="background"
          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          className="circle"
          strokeDasharray={`${anim ? percentage : 0}, 100`}
          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
        />
      </Circle>
      <Content>
        {children}
      </Content>
    </div>
  );
};

const Circle = styled.svg`
  width: 5rem;
  margin-right: 1rem;
  &:not(:root) {
    overflow: hidden;
  }

  @keyframes strokeAnim {
    from {
      stroke-dasharray: 0, 100;
    }
    to {
      stroke-dasharray: ${(props) => props.percentage}, 100;
    }
  }

  .circle {
    fill: none;
    stroke: ${(props) => getColor(props.percentage)};
    stroke-width: 6;
    transition: stroke-dasharray 0.6s ease-in-out;
  }

  .background {
    fill: none;
    stroke: ${(props) => props.theme.gray1};
    stroke-width: 6;
  }

  /*
  this is for animation
  .circle.not-active{
    stroke-dasharray:0 100
  }
  */
`;

const Content = styled.div`
  font-family: ${(props) => props.theme.fontFamilyBold};
  font-size: 1.8rem;
  height: 5rem;
  width: 5rem;
  position: relative;
  margin-top: -5.2rem;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export default CircularChart;
