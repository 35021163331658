import React from 'react';
import GraphTitle from '../graphs/GraphTitle';
import HorizontalGraph from '../graphs/HorizontalGraph';

function LighthouseGraphComponent({
  isAudit,
  scoreId,
  title,
  colors,
  scans,
  marginLeft,
  valueProp,
  calc,
  tooltipFormatter,
  xAxisDomain,
  yAxisDomain,
}) {
  const scores = scans.map((scan) => {
    const desktopScore = calc((scan?.lighthouse.desktop[isAudit ? 'audits' : 'categories']?.find((o) => o.id === scoreId)?.[valueProp]) || 0);
    const mobileScore = calc((scan?.lighthouse.mobile[isAudit ? 'audits' : 'categories']?.find((o) => o.id === scoreId)?.[valueProp]) || 0);
    return {
      name: scan.testUrl.replace('https://', ''),
      desktop: desktopScore,
      mobile: mobileScore,
    };
  })
    .map((data) => {
      if (data.desktop < 0) {
        data.desktop = 0;
      }
      if (data.mobile < 0) {
        data.mobile = 0;
      }
      return data;
    });

  return (
    <>
      <GraphTitle>{title}</GraphTitle>
      <HorizontalGraph scores={scores} marginLeft={marginLeft} colors={colors} valueFormatter={tooltipFormatter} xAxisDomain={xAxisDomain} yAxisDomain={yAxisDomain} />
    </>
  );
}

LighthouseGraphComponent.defaultProps = {
  isAudit: true,
  marginLeft: 0,
  calc: (value) => value,
  tooltipFormatter: (value) => value,
};

export default LighthouseGraphComponent;
