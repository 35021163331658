import React from 'react';

function HipexLogoNoText() {
  return (
    <svg
      width="65.1"
      height="53.12"
      viewBox="0 0 100 100"
    >
      <defs>
        <linearGradient id="a" y1="45.06" x2="107.6" y2="45.06" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#e92160" />
          <stop offset="1" stopColor="#fb7c11" />
        </linearGradient>
      </defs>
      <path
        style={{ fill: 'url(#a)' }}
        className="a"
        d="M106.8.22a1.4,1.4,0,0,0-1.5,0L54.2,27.33,2.2.23A2,2,0,0,0,.7.23,1.71,1.71,0,0,0,0,1.53v59.9a1.36,1.36,0,0,0,.8,1.3L53,89.93a2,2,0,0,0,.7.19h.2a2,2,0,0,0,.7-.19l52.2-27.2a1.56,1.56,0,0,0,.8-1.3V1.53A1.87,1.87,0,0,0,106.8.22Zm-2.3,58.51L57.6,28.93,104.6,4l-.1,54.7ZM50.7,28.93,3.1,58.83V4ZM4.6,61.43,52.2,31.52v54.8ZM56.1,31.52l47,29.91L55.5,86.33Z"
      />
    </svg>

  );
}

export default HipexLogoNoText;
