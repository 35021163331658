import React from 'react';
import {
  CompareBar, CompareBarText, CompareBarTextWrapper, FlexRow,
} from '../components/html';
import WebVitals from '../web-vitals';

function WebVitalsBlock({ scan, marginLeft }) {
  const scans = Array.isArray(scan) ? scan : [scan];

  return (
    <>
      <CompareBar backgroundColor="#F9F1E8">
        <FlexRow center>
          {/* <CompareBarImg src="/img/graph.svg"/> */}
          <span style={{ marginRight: '2rem', fontSize: '2rem' }}>❤️</span>
          <CompareBarTextWrapper>
            <CompareBarText>
              Web Vitals
              {/* {i18n('performanceScanCompareWithCompetitors', 'Benchmark')} */}
            </CompareBarText>
          </CompareBarTextWrapper>
        </FlexRow>
      </CompareBar>

      <WebVitals scans={scans} marginLeft={marginLeft} />

    </>
  );
}

export default WebVitalsBlock;
