import React from 'react';
import moment from 'moment';
import 'moment/locale/nl';
import { useScans } from '../../helpers/get-scan-results';
import ScanResult from './scan-result';
import PerfscanLoader from './components/PerfscanLoader';
import ErrorLoader from './components/ErrorLoader';

moment.locale('nl');

const PerformanceScan = ({
  className,
  title,
  heroBlockImage,
  // internalLink,
}) => {
  const { data, errors, loading } = useScans(1);
  // const { data: stats } = useStats(scan?.testUrl ? formatUrl(scan?.testUrl) : '');

  if (loading) {
    return <PerfscanLoader data={data} />;
  }

  if (errors) {
    return (
      <ErrorLoader errors={errors} />
    );
  }

  // Gatsby's internals use window for the navigate function. This workaround
  // fixes a SSR issue.
  // useEffect(() => {
  //   if (error || (scan && !scan.testUrl)) {
  //     navigate(internalLink?.frontmatter?.slug ?? '/');
  //   }
  // }, [error, internalLink, scan]);

  // TODO: Calc top percentage!

  return (
    <ScanResult
      title={title}
      className={className}
      heroBlockImage={heroBlockImage}
      scans={data[0].tests}
    />
  );
};


export default PerformanceScan;
