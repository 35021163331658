import React from 'react';
import {
  Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis,
} from 'recharts';
import GraphTitle from './GraphTitle';

function ConcurrencyGraph({ scans, colors }) {
  const scores = scans.map((scan) => ({
    url: scan.testUrl,
    score: scan.siege.transactions,
  })).reduce((a, b) => ({
    ...a,
    [b.url.replace('https://', '')]: parseFloat(b.score).toFixed(2),
  }), {});

  const bars = scans.map((scan, index) => (
    <Bar dataKey={scan.testUrl.replace('https://', '')} fill={colors[index]} key={`Siege-Score-${scan.testUrl}`} />
  ));

  return (
    <>
      <GraphTitle>Aantal transacties</GraphTitle>
      <ResponsiveContainer>
        <BarChart data={[scores]}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis domain={[0, 100]} />
          <Tooltip />
          <Legend />
          {bars}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}


export default ConcurrencyGraph;
