import React from 'react';
import ScoreWidget from '../score-widget';
import {
  CompareBar,
  CompareBarImg,
  CompareBarText,
  CompareBarTextWrapper,
  FlexRow,
  StyledContainer, Widgets,
} from '../components/html';

function LighthouseBlock({ scan }) {
  const pageSpeedScoreDesktop = scan?.lighthouse?.desktop.categories?.find((o) => o.id === 'performance')?.score * 100;
  const pageSpeedScoreMobile = scan?.lighthouse?.mobile.categories?.find((o) => o.id === 'performance')?.score * 100;
  const seoScoreDesktop = scan?.lighthouse?.desktop.categories?.find((o) => o.id === 'seo')?.score * 100;
  const seoScoreMobile = scan?.lighthouse?.mobile.categories?.find((o) => o.id === 'seo')?.score * 100;

  return (
    <>
      <CompareBar backgroundColor="#F8EDF1">
        <FlexRow center>
          {/* <CompareBarImg src="/img/graph.svg"/> */}
          <CompareBarImg src="/img/lighthouse-logo.svg" />
          <CompareBarTextWrapper>
            <CompareBarText>
              Lighthouse
              {/* {i18n('performanceScanCompareWithCompetitors', 'Benchmark')} */}
            </CompareBarText>
          </CompareBarTextWrapper>
        </FlexRow>
      </CompareBar>
      <StyledContainer>
        <Widgets>
          <ScoreWidget
            index={0}
            name="Lighthouse Desktop"
            score={pageSpeedScoreDesktop}
          />
          <ScoreWidget
            index={0}
            name="Lighthouse Mobile"
            score={pageSpeedScoreMobile}
          />
          <ScoreWidget
            index={0}
            name="SEO Desktop"
            score={seoScoreDesktop}
          />
          <ScoreWidget
            index={0}
            name="SEO Mobile"
            score={seoScoreMobile}
          />
        </Widgets>
      </StyledContainer>
    </>
  );
}

export default LighthouseBlock;
