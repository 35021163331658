import React from 'react';
import styled from 'styled-components';

function GraphTitle({ children }) {
  return (
    <Title>{children}</Title>
  );
}

const Title = styled.p`
  font-family: ${(props) => props.theme.fontFamilyMedium};
  margin-bottom: 1rem;
  text-align: center;
`;


export default GraphTitle;
