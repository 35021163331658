import React from 'react';
import {
  Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis,
} from 'recharts';
import GraphTitle from './GraphTitle';

function HipexScoreGraph({ scans, colors, marginLeft }) {
  const scores = scans.map((scan) => ({
    name: scan.testUrl.replace('https://', ''),
    score: scan.score.toFixed(0),
  }));

  return (
    <>
      <GraphTitle>Hipex Performance Score (HPS)</GraphTitle>
      <ResponsiveContainer>
        <BarChart
          layout="vertical"
          width={500}
          height={300}
          data={scores}
          margin={{
            left: marginLeft,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" />
          <YAxis dataKey="name" type="category" />
          <Tooltip />
          <Legend />
          <Bar dataKey="score" fill={colors[0]} />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}

export default HipexScoreGraph;
