import React from 'react';
import GraphTitle from './GraphTitle';
import HorizontalGraph from './HorizontalGraph';

function LightHouseScoreGraph({ scans, colors, marginLeft }) {
  const scores = scans.map((scan) => ({
    name: scan.testUrl.replace('https://', ''),
    desktop: scan?.lighthouse.desktop.categories?.find((o) => o.id === 'performance')?.score * 100,
    mobile: scan?.lighthouse.mobile.categories?.find((o) => o.id === 'performance')?.score * 100,
  }))
    .map((data) => {
      if (data.desktop < 0) {
        data.desktop = 0;
      }
      if (data.mobile < 0) {
        data.mobile = 0;
      }
      return data;
    });

  return (
    <>
      <GraphTitle>Lighthouse Performance</GraphTitle>
      <HorizontalGraph scores={scores} marginLeft={marginLeft} colors={colors} valueFormatter={(val) => `${val.toFixed(0)}`} />
    </>
  );
}


export default LightHouseScoreGraph;
