import moment from 'moment';
import React from 'react';
import Container from '../../../components/container';
import Content from '../../../components/content';
import HipexLogoNoText from '../assets/hipex-logo-no-text';
import SiteScreenshot from '../site-screenshot';
import formatUrl from '../../../helpers/format-url';
import {
  Hero,
  HeroLeft, HeroRight,
  HipexScoreLabel,
  HipexScoreScore,
  HPSText,
  HPSWrapper,
  ShowOn,
  SiteTitle,
} from '../components/html';

function ScanHeader({
  scan,
  title,
  heroBlockImage,
}) {
  const siteTitle = formatUrl(scan.testUrl);
  const roundedHipexScore = Math.round(scan.score);
  const finalScreenshot = scan?.lighthouse?.desktop.audits?.find((o) => o.id === 'final-screenshot').details.data;
  const screenshotThumbnails = scan?.lighthouse?.desktop.audits?.find((o) => o.id === 'screenshot-thumbnails')?.details.items;

  const scanDate = scan.updated ? scan.updated.date : scan.created.date;

  return (
    <Container>
      <Hero>
        <HeroLeft>
          <ShowOn desktop>
            <Content>
              <SiteTitle><strong>{siteTitle}</strong></SiteTitle>
              <h3>{title}</h3>
              {moment(scanDate).fromNow()}
            </Content>
          </ShowOn>
          <HPSWrapper>
            <HipexScoreLabel>
              <HipexLogoNoText />
              <HPSText>HPS</HPSText>
            </HipexScoreLabel>
            <HipexScoreScore>
              {roundedHipexScore}
            </HipexScoreScore>
          </HPSWrapper>
        </HeroLeft>
        <ShowOn mobile>
          <Content>
            <SiteTitle><strong>{siteTitle}</strong></SiteTitle>
            <h3>{title}</h3>
            {moment(scan.updated.date).fromNow()}
          </Content>
        </ShowOn>
        <HeroRight>

          <SiteScreenshot
            siteTitle={siteTitle}
            heroBlockImage={heroBlockImage}
            finalScreenshot={finalScreenshot}
            lighthouse={scan.lighthouse}
            thumbnails={screenshotThumbnails}
          />
        </HeroRight>
      </Hero>
    </Container>
  );
}

export default ScanHeader;
