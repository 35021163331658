import React from 'react';
import GraphTitle from './GraphTitle';
import HorizontalGraph from './HorizontalGraph';

function TTIScoreGraph({ scans, colors, marginLeft }) {
  const scores = scans.map((scan) => ({
    name: scan.testUrl.replace('https://', ''),
    desktop: scan?.lighthouse.desktop.audits?.find((o) => o.id === 'interactive')?.numericValue,
    mobile: scan?.lighthouse.mobile.audits?.find((o) => o.id === 'interactive')?.numericValue,
  })).map((score) => ({
    ...score,
    mobile: Math.max(0, score.mobile),
    desktop: Math.max(0, score.desktop),
  }));

  return (
    <>
      <GraphTitle>Time To Interactive (TTI) </GraphTitle>
      <HorizontalGraph scores={scores} marginLeft={marginLeft} colors={colors} valueFormatter={(val) => `${val.toFixed(0)} ms`} />
    </>
  );
}

export default TTIScoreGraph;
