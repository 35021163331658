import React from 'react';
import styled, { css } from 'styled-components';
import CircularChart from './gauge';

const ScoreWidget = ({
  name,
  score,
  index,
}) => {
  const widgetScore = isNaN(score) ? 0 : score;
  return (
    <FlexCol>
      <Widget>
        <WidgetLabel>
          {name}
        </WidgetLabel>
        <ChartWrapper>
          <CircularChart percentage={widgetScore} index={index}>
            {Math.round(widgetScore)}
          </CircularChart>
        </ChartWrapper>
      </Widget>
    </FlexCol>
  );
};

ScoreWidget.defaultProps = {
  score: 0,
};

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChartWrapper = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const Widget = styled.div`
  display: flex;
  align-items: center;
  ${(props) => (props.inline ? 'justify-content: space-between;' : null)}


  ${(props) => css`background: linear-gradient(${props.theme.pastelOrange}, ${props.theme.pastelOrange});`}
  background-repeat: no-repeat;
  background-position: left 2rem center;

  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    ${(props) => {
    if (!props.inline) {
      return 'display: block;';
    }
    return null;
  }}
  }
`;

const WidgetLabel = styled.h3`
  padding-top: 1rem;
  font-size: 1.75rem;
  height: 3em;
  width: 12rem;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    width: auto;
  }
`;

export default ScoreWidget;
